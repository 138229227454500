// This file includes polyfills needed by Angular and is loaded before
// the app. You can add your own extra polyfills to this file.

(window as any).global = window;

import 'zone.js';

/* **************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
